import icon1 from "../assets/images/Icon1.png";
import icon2 from "../assets/images/Icon2.png";
import icon3 from "../assets/images/Icon3.png";

export const notUsItems = [
  {
    id: 1,
    text: `<svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130" fill="none">
    <g clip-path="url(#clip0_1_101)">
    <path d="M64.6392 129.38C100.339 129.38 129.279 100.42 129.279 64.68C129.279 28.94 100.359 0 64.6392 0C28.9192 0 -0.000793457 28.96 -0.000793457 64.7C-0.000793457 100.44 28.9392 129.4 64.6392 129.4" fill="#D3E5F7"/>
    <path d="M46.6992 30.1C41.9992 30.1 38.1792 33.92 38.1792 38.62V89.32C38.1792 89.42 38.3192 89.44 38.3392 89.32C39.0992 85.48 42.4592 82.6 46.5192 82.6H91.0392C91.0392 82.6 91.1192 82.56 91.1192 82.52V30.18C91.1192 30.18 91.0792 30.1 91.0392 30.1H46.7192H46.6992ZM90.9592 99.28H46.9592C42.5592 99.28 38.5992 96.08 38.2192 91.7C37.7792 86.76 41.6792 82.62 46.5192 82.62H90.9592C90.9592 82.62 91.0592 82.7 91.0192 82.74C87.5192 87.66 87.5192 94.24 91.0192 99.16C91.0592 99.22 91.0192 99.28 90.9592 99.28Z" stroke="#303D42" stroke-width="2.24" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M46.2609 82.4601V30.1001M65.8409 59.7201L65.1809 59.0601L58.8009 64.9401C57.8609 65.7801 57.8209 67.2401 58.7209 68.1401C59.6209 69.0401 61.0809 69.0001 61.9209 68.0601L67.6609 61.8201C67.6609 61.8201 67.6809 61.7401 67.6609 61.7001L65.8609 59.7401L65.8409 59.7201ZM70.3009 59.1401L85.8609 43.5801C85.8609 43.5801 85.9009 43.4801 85.8609 43.4401C82.0609 39.9001 70.9609 50.0201 65.8409 55.0201C65.8009 55.0601 65.8009 55.1001 65.8409 55.1401L70.1809 59.1401C70.1809 59.1401 70.2609 59.1601 70.3009 59.1401ZM62.5609 52.1001C62.4009 52.5601 62.1609 52.9601 61.8209 53.3001C61.4809 53.6401 61.0809 53.8801 60.6209 54.0401L74.1609 68.7601C75.0009 69.7001 76.4609 69.7401 77.3609 68.8401C78.2609 67.9401 78.2209 66.4801 77.2809 65.6401L62.5609 52.1001ZM59.8809 44.8601C62.7409 47.7201 63.6209 51.5201 61.8209 53.3201C60.0209 55.1201 56.2409 54.2401 53.3609 51.3801C50.5009 48.5201 49.6209 44.7201 51.4209 42.9201C53.2209 41.1201 57.0009 42.0001 59.8809 44.8601Z" stroke="#303D42" stroke-width="1.12" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1_101">
    <rect width="129.3" height="129.38" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    <p>another</br>recipe book</p>`,
  },
  {
    id: 2,
    text: `<svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130" fill="none">
    <path d="M64.64 129.38C100.34 129.38 129.28 100.42 129.28 64.68C129.28 28.94 100.34 0 64.64 0C28.94 0 0 28.96 0 64.7C0 100.44 28.94 129.4 64.64 129.4" fill="#D3E5F7"/>
    <path d="M64.8787 81.38C64.8787 85 61.8387 87.94 58.0987 87.94C54.3587 87.94 51.3187 85 51.3187 81.38C51.3187 77.76 54.3587 74.84 58.0987 74.84C61.8387 74.84 64.8787 77.78 64.8787 81.38ZM100.939 52.82H85.1387V81.38H90.6987C90.6987 77.76 93.7388 74.84 97.4788 74.84C101.219 74.84 104.279 77.78 104.279 81.38H111.999V63.5C107.679 59.34 105.259 57 100.939 52.82ZM40.7388 41.46V81.38H51.2987C51.2987 77.76 54.3388 74.84 58.0788 74.84C61.8188 74.84 64.8587 77.78 64.8587 81.38H85.1188V41.46H40.7188H40.7388Z" stroke="#303D42" stroke-width="2.24" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M58.1014 83.2999C56.9014 83.2999 55.9414 82.4399 55.9414 81.3799C55.9414 80.3199 56.9214 79.4399 58.1014 79.4399C59.2814 79.4399 60.2614 80.2999 60.2614 81.3799C60.2614 82.4599 59.2814 83.2999 58.1014 83.2999Z" stroke="#303D42" stroke-width="1.54" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M104.279 81.3798C104.279 84.9998 101.239 87.9398 97.4992 87.9398C93.7592 87.9398 90.6992 84.9998 90.6992 81.3798C90.6992 77.7598 93.7392 74.8398 97.4992 74.8398C101.259 74.8398 104.279 77.7798 104.279 81.3798Z" stroke="#303D42" stroke-width="2.24" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M97.5 83.3C96.3 83.3 95.34 82.44 95.34 81.38C95.34 80.32 96.32 79.44 97.5 79.44C98.68 79.44 99.66 80.3 99.66 81.38C99.66 82.46 98.68 83.3 97.5 83.3Z" stroke="#303D42" stroke-width="1.54" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.2212 72.7601H17.2812M35.4212 65.5601H22.4812M30.2212 57.2801H17.2812M35.4212 50.0801H22.4812" stroke="#303D42" stroke-width="2.24" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
     <p>another</br>delivery service</p>`,
  },
  {
    id: 3,
    text: `<svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130" fill="none">
    <g clip-path="url(#clip0_1_121)">
    <path d="M64.6384 129.38C100.338 129.38 129.278 100.42 129.278 64.6799C129.278 28.9399 100.338 -6.10352e-05 64.6384 -6.10352e-05C28.9384 -6.10352e-05 -0.0015564 28.9599 -0.0015564 64.6999C-0.0015564 100.44 28.9384 129.4 64.6384 129.4" fill="#D3E5F7"/>
    <path d="M24.7367 30.6201H36.2167L45.1967 81.0201H92.3167M88.9367 92.84C88.9367 96.4 86.0567 99.3001 82.4767 99.3001C78.8967 99.3001 76.0167 96.42 76.0167 92.84C76.0167 89.26 78.8967 86.3801 82.4767 86.3801C86.0567 86.3801 88.9367 89.28 88.9367 92.84ZM61.4967 92.84C61.4967 96.4 58.5967 99.3001 55.0367 99.3001C51.4767 99.3001 48.5767 96.42 48.5767 92.84C48.5767 89.26 51.4767 86.3801 55.0367 86.3801C58.5967 86.3801 61.4967 89.28 61.4967 92.84ZM85.2367 70.4001H43.3167L37.6367 38.5601H93.4167L85.2367 70.4001Z" stroke="#303D42" stroke-width="2.24" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M77.6001 46.9399V61.9999M69.5601 46.9399V61.9999M61.5001 46.9399V61.9999M53.4601 46.9399V61.9999" stroke="#303D42" stroke-width="1.12" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1_121">
    <rect width="129.3" height="129.38" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    <p>another</br>grocery e-shop</p>`,
  },
];
