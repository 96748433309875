import React from "react";
import line from "../../assets/images/Line.png";
import SoonItem from "./SoonItem";

const LandingSectionRight = () => {
  return (
    <div className="landing-section-right">
      <div className="landing-section-right-text">
        <p>
          The future <br />
          of Shop’n’cook
          <br />
          begins{" "}
          <span className="line-wrapper">
            <span className="line-throught">
              now <img src={line} alt="line" className="line" />
            </span>
            <SoonItem />
          </span>
        </p>
      </div>
    </div>
  );
};

export default LandingSectionRight;
